<template>
<n-alert v-if="marketplace.localization?.['marketplace/i_mentor/info']" class="rounded-lg" type="warning" size="large">
    <div
        class="notes text-xs"
        v-html="marketplace.localization?.['marketplace/i_mentor/info']">
    </div>
</n-alert>

<div v-if="!init" class="flex h-full justify-center items-center py-60">
    <n-spin size="small" />
</div>
<div v-else>
    <super-table
        showSearch
        :filters="filters"
        :actions="actions"
        :loading="loadings.table"
        :pager="marketplace.iMentorPager"
        :columns="marketplace.iMentorColumns"
        :records="marketplace.iMentorRecords"
        columnsStorageName="__columns_marketplace-i-mentor"
        type="marketplace.i_mentor"
        @getData="getData"
        @sortColumn="sortColumn"
        @doSomething="doSomething"
        @applyFilters="changeFilter">
        <template #tableTitle>
            <div class="text-base text-left mb-2">
                {{ marketplace.localization?.['marketplace/i_mentor/grid/title'] }}:
                <span class="text-main font-semibold text-lg">{{ marketplace.iMentorTotal }}</span>
            </div>
        </template>
    </super-table>
</div>

<base-modal-desktop
    v-if="questionInfo"
    lightDark
    :label="`#${questionInfo.id} - ${questionInfo.subject}`"
    v-model:show="showQuestionInfo">
    <template #default>
        <div class="notes" v-html="questionInfo.question"></div>
        <n-alert v-if="questionInfo.state === 'await_confirmation'" class="rounded-lg mt-4" type="warning" size="large">
            <template #header>
                <n-collapse arrow-placement="right">
                    <n-collapse-item>
                        <template #header>
                            <div class="text-xs" v-html="marketplace.localization?.['marketplace/mentors/dialogs/request_mentorship_agreement']"></div>
                        </template>
                        <div
                            class="notes text-xs"
                            v-html="marketplace.localization?.['marketplace/i_mentor/dialogs/mentorship_before_accept_warning']">
                        </div>
                    </n-collapse-item>
                </n-collapse>
            </template>
        </n-alert>
    </template>
    <template #footer v-if="questionInfo.state === 'await_confirmation' || questionInfo.state === 'expired'">
        <div class="w-full flex justify-end items-center">
            <rb-checkbox
                :label="marketplace.localization?.['marketplace/mentors/dialogs/license_agreement']"
                v-model:checked="questionInfoAgree" />
            <!-- :color="questionInfo.state === 'confirmed' ? gl.mainColor : '#dc3545'" -->
            <n-button
                v-if="questionInfo.state === 'await_confirmation'"
                strong
                class="rounded-md text-white/90 ml-4"
                :color="gl.mainColor"
                :loading="questionInfoLoading"
                :disabled="buttonDisabled || questionInfoLoading"
                @click="onConfirmMentorship">
                {{ marketplace.localization?.['marketplace/i_mentor/grid/accept'] }}
            </n-button>
            <n-button
                v-if="questionInfo.state === 'expired'"
                strong
                class="rounded-md text-white/90 ml-4"
                :color="gl.mainColor"
                :loading="questionInfoLoading"
                :disabled="buttonDisabled || questionInfoLoading"
                @click="requestQuestionInfo">
                {{ marketplace.localization?.['marketplace/my_mentors/grid/mentorship_done'] }}
            </n-button>
        </div>
    </template>
</base-modal-desktop>
</template>

<script>
// general
import general from './general';

// icons
import { Warning24Filled, Dismiss16Filled } from '@vicons/fluent';

// UI
import {
    NIcon,
    NCard,
    NSpin,
    NTabs,
    NAlert,
    NModal,
    NInput,
    NSlider,
    NButton,
    NTabPane,
    NCheckbox,
    NCollapse,
    NCollapseItem } from 'naive-ui';

// components
import SuperTable from '@components/super-table/index.vue';

// editor
import Editor from '@tinymce/tinymce-vue';

export default {
    name: 'i-mentor-desktop',
    components: {
        NSpin,
        NIcon,
        NCard,
        NTabs,
        Editor,
        NAlert,
        NModal,
        NInput,
        NSlider,
        NButton,
        NTabPane,
        NCollapse,
        NCheckbox,
        SuperTable,
        NCollapseItem,
        Dismiss16Filled,
        Warning24Filled,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(.notes) {
    * {
        background-color: transparent !important;
        font-size: 16px !important;
    }

    p:not(:first-child) {
    margin-top: 14px;

    &[style='color:red'] {
        color: rgb(208, 58, 82) !important;
    }
    }

    a {
    text-decoration: underline;
    }

    ul {
    margin-top: 14px;
    list-style: circle;
    padding-left: 24px;
    }
}
</style>