<template>
<n-alert v-if="marketplace.localization?.['marketplace/i_mentor/info']" class="rounded-md" type="warning" size="small">
    <div
        class="notes text-[10px]"
        v-html="marketplace.localization?.['marketplace/i_mentor/info']">
    </div>
</n-alert>

<div v-if="!init" class="flex h-full justify-center items-center py-20">
    <n-spin size="small" />
</div>
<div v-else>
    <super-table
        showSearch
        :filters="filters"
        :actions="actions"
        :loading="loadings.table"
        :pager="marketplace.iMentorPager"
        :columns="marketplace.iMentorColumns"
        :records="marketplace.iMentorRecords"
        columnsStorageName="__columns_marketplace-i-mentor"
        type="marketplace.i_mentor"
        @getData="getData"
        @sortColumn="sortColumn"
        @doSomething="doSomething"
        @applyFilters="changeFilter">
        <template #tableTitle>
            <div class="text-sm text-left mb-2">
                {{ marketplace.localization?.['marketplace/i_mentor/grid/title'] }}
                <span class="text-main font-semibold text-md">{{ marketplace.iMentorTotal }}</span>
            </div>
        </template>
    </super-table>
</div>

<n-drawer
    :auto-focus="false"
    v-model:show="showQuestionInfo"
    width="100%"
    class="bg-gray-100 dark:bg-darkbg">
    <n-drawer-content body-content-style="padding: 0 12px;" :auto-focus="false">
        <div class="flex flex-col h-full">
            <div class="relative w-full bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-4">
                <n-icon
                    size="20"
                    class="text-gray-600 dark:text-white/75 absolute top-1/2 left-0 -translate-y-1/2"
                    @click="showQuestionInfo = false">
                    <ArrowLeft12Regular />
                </n-icon>
                {{ `#${questionInfo.id} - ${questionInfo.subject}` }}
            </div>
            <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide">
                <div class="notes text-[10px]" v-html="questionInfo.question"></div>
                <n-alert v-if="questionInfo.state === 'await_confirmation'" class="rounded-md mt-4" type="warning" size="small">
                    <template #header>
                        <n-collapse arrow-placement="right">
                            <n-collapse-item>
                                <template #header>
                                    <div class="text-[10px]" v-html="marketplace.localization?.['marketplace/mentors/dialogs/request_mentorship_agreement']"></div>
                                </template>
                                <div
                                    class="notes text-[10px]"
                                    v-html="marketplace.localization?.['marketplace/i_mentor/dialogs/mentorship_before_accept_warning']">
                                </div>
                            </n-collapse-item>
                        </n-collapse>
                    </template>
                </n-alert>
            </div>
            <div
                v-if="questionInfo.state === 'await_confirmation' || questionInfo.state === 'expired'"
                class="w-full sticky bottom-0 z-50 py-2 flex justify-end items-center">
                <rb-checkbox
                    class="mr-2"
                    :label="marketplace.localization?.['marketplace/mentors/dialogs/license_agreement']"
                    v-model:checked="questionInfoAgree" />
                <n-button
                    v-if="questionInfo.state === 'await_confirmation'"
                    strong
                    class="rounded-md text-white/90 ml-4"
                    :color="gl.mainColor"
                    :loading="questionInfoLoading"
                    :disabled="buttonDisabled || questionInfoLoading"
                    @click="onConfirmMentorship">
                    {{ marketplace.localization?.['marketplace/i_mentor/grid/accept'] }}
                </n-button>
                <n-button
                    v-if="questionInfo.state === 'expired'"
                    strong
                    class="rounded-md text-white/90 ml-4"
                    :color="gl.mainColor"
                    :loading="questionInfoLoading"
                    :disabled="buttonDisabled || questionInfoLoading"
                    @click="requestQuestionInfo">
                    {{ marketplace.localization?.['marketplace/my_mentors/grid/mentorship_done'] }}
                </n-button>
            </div>
        </div>
    </n-drawer-content>
</n-drawer>
</template>

<script>
// general
import general from './general';

// icons
import { Warning24Filled, Dismiss16Filled, ArrowLeft12Regular } from '@vicons/fluent';

// UI
import {
    NIcon,
    NCard,
    NSpin,
    NTabs,
    NAlert,
    NModal,
    NInput,
    NSlider,
    NButton,
    NDrawer,
    NTabPane,
    NCheckbox,
    NCollapse,
    NDrawerContent,
    NCollapseItem } from 'naive-ui';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';
import SuperTable from '@components/super-table/mobile.vue';

// editor
import Editor from '@tinymce/tinymce-vue';

export default {
    name: 'i-mentor-mobile',
    components: {
        NSpin,
        NIcon,
        NCard,
        NTabs,
        Editor,
        NAlert,
        NModal,
        NInput,
        NSlider,
        NButton,
        RbInput,
        NDrawer,
        NTabPane,
        NCollapse,
        NCheckbox,
        SuperTable,
        RbCheckbox,
        NCollapseItem,
        Dismiss16Filled,
        Warning24Filled,
        NDrawerContent,
        ArrowLeft12Regular,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>